/** @jsx jsx */
import { useStaticQuery, graphql, Link } from "gatsby"
import * as C from "../components/containers"
import * as H from "../components/headings"
import Img from "gatsby-image"
import { jsx, Button } from "theme-ui"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Home Bird.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      sx={{
        display: [null, null, "flex", "flex"],
        position: "relative",
        height: "100vh",
      }}
    >
      <div
        sx={{
          width: ["100%", "100%", "50%", "50%"],
          position: ["absolute", "absolute", "relative", "relative"],
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: [
            "hsla(71,85%,97%,0.4)",
            "hsla(71,85%,97%,0.4)",
            "white",
            "white",
          ],
          top: 0,
          left: 0,
          zIndex: 1,
          height: ["100%", "100%", "auto", "auto"],
        }}
      >
        <H.h1 sx={{ fontSize: [5, 6, 6, 7], color: ["black", "black", "primary", "primary"] }}>Crow & Apricot</H.h1>
        <p sx={{ color: ["black", "black", "hsl(1,0%,50%)", "hsl(1,0%,50%)"] }}>
          Wildlife - Portraiture - Design
        </p>
        <Link
          to="/gallery"
          sx={{ textDecoration: "none", color: "gray", marginTop: "1rem" }}
        >
          <Button variant="primary">Enter Site</Button>
        </Link>
      </div>
      <Img
        fluid={data.file.childImageSharp.fluid}
        alt="Crow & Apricot logo"
        sx={{
          width: ["100%", "100%", "50%", "50%"],
          height: "100%",
        }}
        loading="eager"
      />
    </div>
  )
}
